import type { SysTagLibSimpleVO } from '@/pages/employer/employerManagement/type';
import type { PositionDetailVO } from '@/pages/positionManagement/components/type';
import type { SysPositionVO } from '@/pages/positionManagement/interface';
import type {
  BaseData,
  FilterTreeSelect,
  IMainSubStructureModel,
  NormalProgrammeParams,
  PaginationData,
} from '@/utils';
import {
  RenderByPermission,
  SearchListModal,
  copyToClipboard,
  filterTree,
  getWxUrl,
  request,
  transformKeys,
} from '@/utils';
import { api } from '@/utils/api';
import { DownloadOutlined, LinkOutlined } from '@ant-design/icons';
import { Modal, Tag, message } from 'antd';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { action, observable } from 'mobx';
import type React from 'react';
import ImportModel from '../../../compnents/import/impoortModel';
import { API } from '../api';
import type { LiveTypeListVO } from '../type';
import ApprovalModel from './checkModal/model';
import {
  acceptNewbieDict,
  anchorSexDict,
  auditStatusDict,
  bindStatusDict,
  contactWXDict,
  jobTypeDict,
  positionStatusDict,
  showPartBrokerDict,
} from './dict';
import RecommendedPositionModel from './recommendedPositionModel/model';
import RelevancyEmployerModel from './relevancyEmployer/model';
import { ShowToPartBrokerModel } from './showToPartBroker';

const { confirm, error } = Modal;

export default class Model {
  private isDownloading: boolean;
  @observable public rowData: PositionDetailVO;
  @observable public wxCode: string;

  // 控制导入弹窗
  @observable public importModalVisible = false;

  // 导入失败弹窗
  @observable public importFailModalVisible = false;

  // 雇主关联弹窗
  public relevancyEmployerStore = new RelevancyEmployerModel(this);
  public importStore = new ImportModel(this);

  public showToPartBrokerModel = new ShowToPartBrokerModel(this);

  // 推荐弹窗
  public recommendedPositionModel = new RecommendedPositionModel(this);

  // 审核弹窗
  public approvalStore = new ApprovalModel(this);
  constructor() {
    this.mainStore.grid.onQuery();
    this.init();
  }

  init = () => {
    request<BaseData<SysTagLibSimpleVO[]>>({
      url: api.tag.getAllLibList,
      method: 'GET',
      params: {
        categoryType: 3,
      },
    }).then((res) => {
      const treeData = transformKeys(res.data, {
        name: 'label',
        id: 'value',
      });
      this.mainStore.programme.filterItems.updateFilterItem([{ field: 'tagIdList', treeData }]);
    });
    request<PaginationData<LiveTypeListVO>>({
      url: '/pbb/platform/anon/system/liveType/list',
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    }).then((res) => {
      this.mainStore.programme.filterItems.updateFilterItem([
        {
          field: 'liveTypeList',
          treeData: transformKeys(res.data, {
            name: 'label',
            id: 'value',
            childrenList: 'children',
          }),
        },
      ]);
    });
  };

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'positionName',
        label: '职位名称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'companyName',
        label: '公司名称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'positionCity',
        label: '岗位城市',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'jobType',
        label: '职位类型',
        placeholder: '请选择',
        data: jobTypeDict,
      },
      {
        type: 'select',
        field: 'jobStatus',
        label: '职位状态',
        data: positionStatusDict,
      },
      {
        type: 'select',
        field: 'isContactWxCode',
        label: '联系微信号',
        data: contactWXDict,
      },
      {
        type: 'select',
        field: 'hasContactMobile',
        label: '联系电话',
        data: contactWXDict,
      },
      {
        type: 'select',
        field: 'bindStatus',
        label: '关联状态',
        data: bindStatusDict,
      },
      {
        type: 'select',
        field: 'auditStatus',
        label: '审核状态',
        data: auditStatusDict,
      },
      {
        type: 'select',
        field: 'showToPartBroker',
        label: '展示给兼职经纪人',
        data: showPartBrokerDict,
      },
      {
        type: 'treeSelect',
        treeCheckable: true,
        multiple: true,
        field: 'liveTypeList',
        label: '直播类目',
        showSearch: true,
        treeNodeFilterProp: 'label',
      },
      {
        type: 'select',
        field: 'anchorSex',
        label: '主播性别',
        data: anchorSexDict,
      },
      {
        type: 'select',
        field: 'acceptNewBie',
        label: '接受小白',
        data: acceptNewbieDict,
      },
      {
        type: 'inputNumberGroup',
        field: 'salaryRange',
        label: '薪资范围',
      },
      {
        type: 'input',
        field: 'updateByKeyword',
        label: '操作人',
      },
      {
        type: 'dateRange',
        field: 'createDateRange',
        label: '创建时间',
      },
      {
        type: 'treeSelect',
        field: 'tagIdList',
        label: '职位标签',
        treeCheckable: true,
        multiple: true,
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        permissionId: '28',
        text: '导入',
        iconNode: <DownloadOutlined />,
        handleClick: () => {
          this.importStore.openImportModal('0');
        },
      },
      {
        permissionId: '63',
        text: '关联雇主',
        iconNode: <LinkOutlined />,
        handleClick: () => {
          const rows = this.mainStore.grid.gridModel.selectRows;
          const unBindRows = rows.filter((row) => row.bindStatus !== 0);
          if (!rows.length || unBindRows.length) {
            message.warning('请选择职位且只选择【关联状态】为【未绑定】的职位');
            return;
          }
          this.onRelevancyEmployer(Array.from(this.mainStore.grid.gridModel.selectedIds));
        },
      },
      {
        permissionId: '14',
        text: '批量审核',
        handleClick: () => {
          const ids = this.mainStore.grid.gridModel.selectRows
            .filter((item) => item.auditStatus !== undefined && item.auditStatus !== null)
            .map((item) => item.id);
          if (ids.length < 1) {
            message.warning('请选择有【审核状态】的职位!');
            return;
          }
          this.approvalStore.onOpenApproveModal(ids, true);
        },
      },
      {
        permissionId: '12',
        text: '批量上线',
        handleClick: () => {
          const rows = this.mainStore.grid.gridModel.selectRows;
          const waitUpRows = rows.filter((row) => row.jobStatus === 2);
          const ids = waitUpRows.map((v) => v.id);
          if (!rows.length || !waitUpRows.length) {
            message.warning('请选择【职位状态】为【待上线】的职位!');
            return;
          }
          this.batchOnLine(ids);
        },
      },
      {
        permissionId: '12',
        text: '批量下线',
        handleClick: () => {
          const len = this.mainStore.grid.gridModel.selectRows.length;
          const ids = this.mainStore.grid.gridModel.selectRows
            .filter((item) => item.jobStatus == 1)
            .map((item) => item.id);
          if (ids.length < 1) {
            message.warning('请选择【职位状态】 为【已上线】的职位!');
            return;
          }
          this.batchOffline(ids);
        },
      },

      {
        text: '批量展示给兼职经纪人',
        handleClick: () => {
          const ids = this.mainStore.grid.gridModel.selectRows.map((item) => item.id);
          if (ids.length < 1) {
            message.warning('请选择批量展示给兼职经纪人的职位');
            return;
          }
          this.showToPartBrokerModel.onOpen(ids);
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          frozen: true,
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            const { jobStatus, id, bindStatus, auditStatus } = row;
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <a
                    key="detail"
                    className="px-1"
                    onClick={() => {
                      window.top.RingPermission.openTab(
                        `/pbb-pc-management/positionManagement/index?positionId=${id}&isShowDetail=${true}`,
                        '76',
                        '职位管理',
                      );
                    }}
                    type="link"
                  >
                    详情
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_63`}>
                  {bindStatus === 0 && (
                    <a
                      key="relevance"
                      className="px-1"
                      onClick={() => {
                        this.onRelevancyEmployer([id]);
                      }}
                      type="link"
                    >
                      关联雇主
                    </a>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_12`}>
                  {bindStatus !== 0 && (
                    <a
                      key="online"
                      className="px-1"
                      onClick={() => {
                        this.onLineOrOffline(id, jobStatus === 1 ? 1 : 2);
                      }}
                      type="link"
                    >
                      {jobStatus === 1 ? '下线' : jobStatus === 2 ? '上线' : null}
                    </a>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_86`}>
                  {jobStatus === 1 && (
                    <a
                      className="px-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        getWxUrl({
                          channel: 1,
                          positionId: row.id,
                        }).then((url) => {
                          copyToClipboard(url);
                          message.success('复制分享职位链接成功');
                        });
                      }}
                    >
                      分享
                    </a>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_14`}>
                  {[0, 1, 2].includes(auditStatus) && (
                    <a
                      key="check"
                      className="px-1"
                      onClick={() => {
                        this.approvalStore.onOpenApproveModal([id], true);
                      }}
                      type="link"
                    >
                      审核
                    </a>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_85`}>
                  {jobStatus === 1 && (
                    <a
                      className="px-1"
                      onClick={() => {
                        this.recommendedPositionModel.onOpen([row.id] as string[]);
                      }}
                    >
                      推荐
                    </a>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_87`}>
                  {jobStatus === 1 && (
                    <a
                      className="px-1"
                      type="link"
                      onClick={() => {
                        this.downloadPositionCard(row);
                      }}
                    >
                      下载职位卡片
                    </a>
                  )}
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '职位名称',
        },
        {
          key: 'jobType',
          name: '职位类型',
          formatter: ({ row }) => {
            return jobTypeDict.find((item) => item.value == row.jobType)?.label;
          },
        },
        {
          key: 'liveTypeName',
          name: '直播类目',
        },
        {
          key: 'positionCity',
          name: '岗位城市',
        },
        {
          key: 'tagVOList',
          name: '职位标签',
          formatter: ({ row }) => {
            const iconList = row?.tagVOList || [];
            return iconList.map(({ libId, libName }) => (
              <Tag
                color="green"
                key={libId}
              >
                {libName}
              </Tag>
            ));
          },
        },
        {
          key: 'positionStatusName',
          name: '职位状态',
        },
        {
          key: 'auditStatus',
          name: '审核状态',
          formatter: ({ row }) => {
            return auditStatusDict.find((item) => item.value == row.auditStatus)?.label;
          },
        },
        {
          key: '最新审核人',
          name: '最新审核人',
          formatter: ({ row }) => {
            return (
              <div className="flex items-center cursor-pointer h-full gap-2">
                <div className="text-12 leading-[18px]">
                  <p>昵称：{row.auditAdminName}</p>
                  <p>ID：{row.auditAdminId}</p>
                  <p>手机号：{row.auditAdminMobile}</p>
                </div>
              </div>
            );
          },
        },
        {
          key: 'auditTime',
          name: '最新审核时间',
          minWidth: 166,
          formatter: ({ row }) => {
            return row.auditTime ? dayjs(row.auditTime).format('YYYY-MM-DD HH:mm:ss') : null;
          },
        },
        {
          key: 'showToPartBroker',
          name: '展示给兼职经纪人',
          minWidth: 166,
          formatter: ({ row }) => {
            return row.showToPartBroker == 1 ? '是' : row.showToPartBroker == 0 ? '否' : '';
          },
        },
        {
          key: 'companyName',
          name: '公司名称',
          minWidth: 166,
        },
        {
          key: 'contactMobile',
          name: '联系电话',
          minWidth: 166,
        },
        {
          key: 'contactWxCode',
          name: '联系微信',
          minWidth: 166,
        },
        {
          key: 'signUpCount',
          name: '报名人数',
        },
        {
          key: 'unEndCount',
          name: '状态进行中',
        },
        {
          key: 'validity',
          name: '有效期',
        },
        {
          key: 'bindStatusName',
          name: '关联状态',
          formatter: ({ row }) => {
            return bindStatusDict.find((item) => item.value == row.bindStatusName)?.label;
          },
        },
        {
          key: 'anchorSex',
          name: '主播性别',
          formatter: ({ row }) => {
            return anchorSexDict.find((item) => item.value == row.anchorSex)?.label || '不限';
          },
        },
        {
          key: 'acceptNewBie',
          name: '接受小白',
          formatter: ({ row }) => {
            return acceptNewbieDict.find((item) => item.value == row.acceptNewBie)?.label;
          },
        },
        {
          key: 'createByName',
          name: '操作人',
        },
        {
          key: 'updateTime',
          name: '操作时间',
          minWidth: 166,
          formatter: ({ row }) => {
            return row.updateTime ? dayjs(row.updateTime).format('YYYY-MM-DD HH:mm:ss') : null;
          },
        },
        {
          key: 'createTime',
          name: '创建时间',
          minWidth: 166,
          formatter: ({ row }) => {
            return row.createTime ? dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') : null;
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
      rowHeight: 70,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        if (postParams?.tagIdList) {
          postParams.tagIdList = postParams.tagIdList.split(',');
        }
        postParams.salaryRange = postParams.salaryRange?.split(',') || [];
        postParams.createDateRange = postParams.createDateRange?.split(',') || [];
        Object.assign(postParams, {
          clue: postParams.bindStatus ? 0 : 1,
          minSalary: postParams.salaryRange?.[0],
          maxSalary: postParams.salaryRange?.[1],
          createTimeStart: postParams.createDateRange?.[0],
          createTimeEnd: postParams.createDateRange?.[1],
          createDateRange: undefined,
          salaryRange: undefined,
        });
        if (postParams?.liveTypeList) {
          const ids = new Set<string>(postParams.liveTypeList.split(','));
          const dataCell = this.mainStore.programme.filterItems.originData.find(
            (item) => item.field === 'liveTypeList',
          ) as FilterTreeSelect;
          const levels = filterTree(dataCell.treeData, ids);
          postParams.liveTypeList = transformKeys(levels, {
            children: 'childrenList',
            value: 'id',
            label: 'name',
          });
        }

        return request<BaseData<any>>({
          url: API.noCooperateList,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  @action public onRelevancyEmployer = async (ids?: React.Key[]) => {
    confirm({
      content: '是否确认关联雇主账号？',
      onOk: async () => {
        const res = await request<BaseData<{ employerInfoVOList: any[]; positionIdList: string[] } | string>>({
          url: API.relevancyEmployer,
          method: 'POST',
          data: {
            idList: ids,
          },
        });
        const { data } = res;
        // 如果data是字符串，直接提示用户重新选择，如果data是个对象，则弹窗关联
        if (data && typeof data === 'string') {
          error({
            content: data,
          });
          return;
        }
        if (data && typeof data === 'object' && Object.keys(data).length) {
          // 弹窗直接赋值gridModel的rows,并且传过去职位id
          this.relevancyEmployerStore.onOpenModal(data.positionIdList);
          this.relevancyEmployerStore.gridModel.rows = data.employerInfoVOList;
          return;
        }
        message.success('操作成功');
        this.mainStore.grid.gridModel.onRefresh();
      },
    });
  };
  @action public onMultiCount = async () => {
    confirm({
      content: `${[1, 2, 3].map((v) => `【${v}公司】`)}名下有多个雇主账号，需选择绑定的雇主信息`,
      onOk: async () => {
        // this.relevancyEmployerStore.onOpenModal();
        const res = await request<BaseData>({
          url: API.confirmRelevancy,
          method: 'POST',
          data: {
            positionIdList: Array.from(this.mainStore.grid.gridModel.selectedIds),
          },
        });
      },
    });
  };

  // 启用禁用表格项
  @action
  private onLineOrOffline = (id, status: 1 | 2) => {
    confirm({
      title: '操作提示',
      content: `是否${status === 2 ? '上线' : '下线'}该职位？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await request<BaseData<string>>({
          url: API.enabelPostion,
          method: 'POST',
          data: { id, status },
        });
        message.success(result.data || result.msg || '操作成功');
        this.mainStore.grid.gridModel.onRefresh();
      },
    });
  };

  // 批量下线前查找选中职位下是否有在流程中的主播
  @action
  private withProcess = (positionList) => {
    return new Promise((resolve, reject) => {
      request<BaseData<string[]>>({
        url: API.withProcess,
        method: 'POST',
        data: { positionList },
      }).then((res) => {
        if (res?.data?.length > 0) {
          confirm({
            title: '操作提示',
            content: `职位【${res.data.join(',')}】下有已报名的主播，确认下线？`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              resolve(true);
            },
            onCancel: () => {
              resolve(false);
            },
          });
        } else {
          resolve(true);
        }
      });
    });
  };
  // 批量上线
  @action
  private batchOnLine = (positionList) => {
    confirm({
      title: '操作提示',
      content: '是否批量上线职位？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await request<BaseData<string>>({
          url: API.batchEnablePosition,
          method: 'POST',
          data: { positionList, status: 2 },
        });
        message.success(result.data || result.msg || '操作成功');
        this.mainStore.grid.gridModel.onRefresh();
      },
    });
  };
  // 批量下线
  @action
  private batchOffline = (positionList) => {
    const confirmController = confirm({
      title: '操作提示',
      content: '是否批量下线职位？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const isSure = await this.withProcess(positionList);
        if (isSure) {
          const result = await request<BaseData<string>>({
            url: API.batchDisablePosition,
            method: 'POST',
            data: { positionList, status: 1 },
          });
          message.success(result.data || result.msg || '操作成功');
          this.mainStore.grid.gridModel.onRefresh();
        }
      },
    });
  };

  @action downloadPositionCard = async (row: SysPositionVO) => {
    try {
      if (this.isDownloading) {
        message.warning('处理中,请稍后');
        return;
      }
      this.isDownloading = true;

      await this.getPositionInfo(String(row.id));

      this.wxCode = await getWxUrl({
        channel: 1,
        positionId: row.id,
      });
      setTimeout(() => {
        this.downPic('positionCard', `职位卡片-${row.name}`);
      }, 10);
    } catch (error) {
    } finally {
      this.isDownloading = false;
    }
  };

  // 下载图片
  @action public downPic = async (id: string, name: string) => {
    const canvas2 = document.createElement('canvas');
    const _element = document.getElementById(`${id}Element`);

    const w = Number.parseInt(window.getComputedStyle(_element).width, 10);
    const h = Number.parseInt(window.getComputedStyle(_element).height, 10);

    canvas2.width = w * 4;
    canvas2.height = h * 4;
    const context = canvas2.getContext('2d');
    context.imageSmoothingEnabled = false;
    context.scale(4, 4);

    const canvas = await html2canvas(_element, {
      canvas: canvas2,
      scale: 1,
      useCORS: true,
    });
    canvas.toBlob((blob) => {
      FileSaver.saveAs(blob, name);
    });
  };

  // 获取职位信息
  @action public getPositionInfo = async (id: string) => {
    const req = await request<BaseData<{ [key: string]: any }>>({
      method: 'POST',
      url: '/pbb/platform/sys/position/getPositionDetailById',
      data: {
        id: id,
      },
    });
    this.rowData = req.data;
  };
  @action public onRefresh = () => {
    this.mainStore.grid.gridModel.onRefresh();
  };
}
