import { ASSETS } from '@/assets';
import {
  type BaseData,
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  RenderByPermission,
  SearchListModal,
  request,
  requireSource,
  transformKeys,
} from '@/utils';
import { Modal, Tag, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import AlternativeModel from './alternativeSlice/model';
import { API } from './api';
import { ChangeBulterModel } from './changeButler/model';
import DetailModel from './employerDetail/model';
import { ServiceRecordModel } from './serviceRecord/model';
import type { SysTagLibSimpleVO } from './type';
const { confirm } = Modal;

export default class Model {
  private exportParams = {};
  // 雇主明细弹窗
  public employerDetailStore = new DetailModel(this);

  // 备选切片弹窗
  public alternativeSliceStore = new AlternativeModel(this);

  //服务记录弹框
  public serviceRecordStore = new ServiceRecordModel(this);
  public changeBulterStore = new ChangeBulterModel(this);

  serviceList: any;

  constructor() {
    this.functionTypeList();
    this.mainStore.grid.onQuery();
    this.init();
  }

  init = async () => {
    const result = await request<BaseData<SysTagLibSimpleVO[]>>({
      url: API.tagList,
      method: 'GET',
      params: {
        categoryType: 0,
      },
    });
    if (result.data?.length > 0) {
      this.mainStore.programme.filterItems.updateFilterItem([
        {
          field: 'tagIdList',
          treeData: transformKeys(result.data, {
            name: 'label',
            id: 'value',
          }),
        },
      ]);
    }
  };

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'keyword',
        label: '雇主信息',
        placeholder: '请输昵称/手机号/ID/公司名称',
      },
      {
        type: 'input',
        field: 'butlerName',
        label: '管家姓名',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'bdName',
        label: 'BD姓名',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'serviceTypeList',
        label: '服务名称',
        mode: 'multiple',
      },
      {
        type: 'select',
        field: 'status',
        label: '状态',
        data: [
          {
            label: '封禁',
            value: '1',
          },
          {
            label: '正常',
            value: '0',
          },
        ],
      },
      {
        type: 'treeSelect',
        treeCheckable: true,
        multiple: true,
        field: 'tagIdList',
        label: '雇主标签',
      },
      {
        type: 'input',
        field: 'referrerKeyword',
        label: '注册推荐人',
        placeholder: '请输入昵称/手机号/ID/公司名称',
      },

      {
        type: 'dateRange',
        field: 'createTime',
        label: '注册时间',
      },
      {
        type: 'select',
        field: 'channel',
        label: '注册来源',
        placeholder: '请选择',
      },
      {
        type: 'select',
        field: 'hasPosition',
        label: '发布职位',
        data: [
          { label: '无', value: '0' },
          { label: '有', value: '1' },
        ],
      },
      {
        type: 'select',
        field: 'selfConnection',
        label: '自主建联',
        data: [
          { label: '无', value: '0' },
          { label: '有', value: '1' },
        ],
      },
      {
        type: 'select',
        field: 'butlerProxyRecruit',
        label: '管家代招',
        data: [
          { label: '无', value: '0' },
          { label: '有', value: '1' },
        ],
      },
    ],
  };

  @action public functionTypeList = async () => {
    const dictMaps = await request<BaseData<any>>({
      url: '/pbb/platform/anon/system/sysDict/getDictMap',
      method: 'post',
      data: {
        dictTypeList: ['register_channel', 'employer_service_name'],
      },
    });

    const channelList = dictMaps.data?.register_channel?.map((e) => {
      return { label: e.dictName, value: e.dictValue };
    });
    const serviceList = dictMaps.data?.employer_service_name?.map((e) => {
      return { label: e.dictName, value: e.dictValue };
    });

    serviceList.unshift({ label: '全部', value: '0' }, { label: '免费服务', value: '1' });

    this.mainStore.programme.filterItems.updateFilterItem([{ field: 'channel', data: channelList }]);

    this.mainStore.programme.filterItems.updateFilterItem([{ field: 'serviceTypeList', data: serviceList }]);
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        text: '批量更换管家',
        handleClick: () => {
          const ids = Array.from(this.mainStore.grid.gridModel.selectedIds);
          if (!ids.length) {
            message.warning('请选择要操作的用户');
            return;
          }
          this.changeBulterStore.onShow(ids);
        },
      },
      // {
      //   permissionId: '92',
      //   text: '导出',
      //   iconNode: <UploadOutlined />,
      //   handleClick: () => {
      //     exportXlsx('/pbb/platform/sys/employer/export', this.exportParams, '雇主管理');
      //   },
      // },
    ],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          frozen: true,
          width: 300,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <a
                    className="px-1"
                    onClick={() => {
                      this.employerDetailStore.onOpenDetailModal(row.id);
                    }}
                    type="link"
                  >
                    详情
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <a
                    className="px-1"
                    onClick={() => {
                      this.changeBulterStore.onShow(row.id);
                    }}
                    type="link"
                  >
                    更换招聘管家
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <a
                    className="px-1"
                    onClick={() => {
                      this.serviceRecordStore.onShow(row.id);
                    }}
                    type="link"
                  >
                    服务记录
                  </a>
                </RenderByPermission>

                <RenderByPermission permissionId={`${pageId}_46`}>
                  <a
                    className="px-1"
                    onClick={() => {
                      this.onEnabel(row.id, row.status === 1 ? 0 : 1);
                    }}
                    type="link"
                  >
                    {row.status === 1 ? '解封' : '封禁'}
                  </a>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '雇主信息',
          width: 220,
          formatter: ({ row }) => {
            const { avatar, name, phone, id, isFee, companyName, payCount } = row;
            return (
              <div
                className="flex items-center cursor-pointer h-full gap-2"
                onClick={() => {
                  this.employerDetailStore.onOpenDetailModal(id);
                }}
              >
                <div className="relative">
                  <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                    <img
                      src={avatar || 'https://xbygobs.bocaixingtu.cn/wx-employer/mine/avatar-default.png'}
                      className="w-full h-full"
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">
                    {isFee === 1 ? (
                      <>
                        <img
                          src={ASSETS.COMMON.PAY_STATUS}
                          className="w-13"
                          alt=""
                        />
                        <div className="text-[red]"> ({payCount})</div>
                      </>
                    ) : null}
                  </div>
                  <div className="flex items-center">
                    用户昵称：{name}
                    <img
                      src={requireSource('/common/idcard.png')}
                      alt={''}
                      className={'w-4 h-4 ml-1'}
                    />
                  </div>
                  <div>ID：{id}</div>
                  <div>手机号：{phone}</div>
                  <div style={{ fontSize: '10px', lineHeight: '20px' }}>公司名称：{companyName}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'contactInfo',
          name: '联系方式',
          width: 100,
        },
        {
          key: 'serviceNameList',
          name: '服务名称',
          width: 100,
          formatter: ({ row }) => {
            const { serviceNameList } = row;

            return (
              <div className="flex items-center justify-start  h-full">
                {serviceNameList?.map((e, index) => {
                  return (
                    <p
                      key={`${e.serviceName}_${index}`}
                      className="leading-[normal] ml-2"
                    >
                      {e}
                    </p>
                  );
                })}
              </div>
            );
          },
          // formatter: ({ row }) => userTypeDict[row?.userType] || '',
        },
        {
          key: 'sysTagVOList',
          width: 200,
          name: '雇主标签',
          formatter: ({ row }) => {
            const iconList = row?.sysTagVOList || [];
            return iconList.map(({ libId, libName }) => (
              <Tag
                color="green"
                key={libId}
              >
                {libName}
              </Tag>
            ));
          },
        },
        {
          key: 'phone',
          width: 100,
          name: '绑定手机号',
        },
        {
          key: 'butlerName',
          name: '招聘管家',
          width: 100,
        },
        {
          key: 'bdName',
          name: 'BD姓名',
          width: 100,
        },
        // TODO: 翻译
        // {
        //   key: 'slicePeopleCount',
        //   name: '收藏人数',
        //   width: 80,
        //   formatter: ({ row }) => (
        //     <a
        //       className="text-primary"
        //       onClick={() => {
        //         this.alternativeSliceStore.onOpenModal(row.id);
        //         this.alternativeSliceStore.setSliceModalTitleInfo({
        //           name: row.name,
        //           slicePeopleCount: row.slicePeopleCount,
        //           sliceCount: row.sliceCount,
        //         });
        //       }}
        //     >
        //       {row.slicePeopleCount | 0}人
        //     </a>
        //   ),
        // },
        {
          key: 'positionCount',
          name: '职位数/上线职位数',
          width: 150,
          formatter: ({ row }) => {
            return (
              <a
                className="text-primary"
                onClick={() => {
                  window.top.RingPermission.openTab(
                    `/pbb-pc-management/positionManagement/index?companyName=${row.companyName}`,
                    '76',
                    '职位管理',
                  );
                }}
              >
                {row.positionCount}个/{row.onLinePositionCount}个
              </a>
            );
          },
        },
        {
          key: '自主建联剩余次数',
          name: '自主建联剩余次数',
          formatter: ({ row }) => `${row.selfConnectionCount || 0 + (row.giftSelfConnectionCount || 0)}次`,
        },
        {
          key: 'inPersonCount',
          name: '管家代招次数',
          width: 150,
          formatter: ({ row }) => `${row.inPersonCount}次`,
        },
        {
          key: 'status',
          name: '账号状态',
          width: 100,
          formatter: ({ row }) => {
            return row.status === 1 ? <Tag color="red">封禁</Tag> : <Tag color="green">正常</Tag>;
          },
        },
        {
          key: 'channelName',
          name: '注册来源',
          width: 100,
        },
        {
          key: 'registerInfo',
          name: '注册推荐人信息',
          width: 220,
          formatter: ({ row }) => {
            const { referrerAvatar, referrerName, referrerMobile, referrerId, referrerPayCount, referrerIsFee } = row;
            if (!referrerId) {
              return '';
            }
            return (
              <div className="flex items-center cursor-pointer h-full gap-2">
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">用户昵称：{referrerName}</div>
                  <div>ID：{referrerId}</div>
                  <div>手机号：{referrerMobile}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'createTime',
          name: '注册时间',
          formatter: ({ row }) => {
            const { createTime } = row;
            return createTime ? dayjs(createTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      rowHeight: 120,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.startTime = postParams.createTime.split(',')[0];
          postParams.endTime = postParams.createTime.split(',')[1];
          // biome-ignore lint/performance/noDelete: <explanation>
          delete postParams.createTime;
        }
        if (postParams?.tagIdList) {
          postParams.tagIdList = postParams.tagIdList.split(',');
        }

        if (postParams.serviceTypeList) {
          postParams.serviceTypeList = postParams.serviceTypeList
            .split(',')
            .filter((item) => item !== '0' && item !== '1');
        }
        this.exportParams = postParams;
        return request<BaseData<any>>({
          url: API.queryEmployerPage,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 启用禁用
  @action
  private onEnabel = (userId, status: 1 | 0) => {
    confirm({
      title: '操作提示',
      content: `是否${status === 1 ? '封禁' : '解封'}该雇主账号？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await request<BaseData>({
          url: API.enabelCount,
          method: 'POST',
          data: { userId, status },
        });
        message.success(result.msg || '操作成功');
        this.mainStore.grid.gridModel.onRefresh();
      },
    });
  };

  @action
  public onRefresh = () => {
    this.mainStore.grid.gridModel.onRefresh();
  };
}
